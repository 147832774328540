<template>
  <div class="contents">
      <div class="return" @click="returnTap">{{$t('N_I_241')}}</div>
      <div class="content-width">
        <div class="marketList" v-for="item in bannerLists" @click="navTaps(item)">
            <div class="marketName">{{ item.storeName }}</div>
            <div class="market-info tops">
                <div class="market-icon">
                    <img src="@/assets/images/markerSpace/user.png" />
                </div>
                <div class="name">
                    联系人：{{item.storePerson}}
                </div>
            </div>
            <div class="market-info">
                <div class="market-icon">
                    <img src="@/assets/images/markerSpace/phone.png" />
                </div>
                <div class="name">
                    联系方式：{{item.storePhone}}
                </div>
            </div>
            <div class="market-info">
                <div class="market-icon">
                    <img src="@/assets/images/markerSpace/address1.png" />
                </div>
                <div class="name">
                    地址：{{item.storeProvinceVal}}{{item.storeCityVal}}{{item.storeCountyVal}}{{item.storeAddress}}
                </div>
            </div>
            <div class="marketImg">
                <img :src="item.fileList[0]"/>
            </div>

        </div>

      </div>
      <div>
          <pagination
              :total="total"
              :pageSizes="[15]"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getBankCardChoiceList"
          />
      </div>
  </div>
</template>

<script>
import * as sid from "@/api/sidebaruserinfo.js";
import sidebarUserInfo from "@/components/sidebarUserInfo";
import topBanner from "@/components/topBanner";
import markerSpace from "@/components/markerSpace";
import * as api from "@/api/register";
import { getBankCardChoiceList } from "@/api/wallet";
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
        bannerLists:[],
        total:0,
        queryParams: {
            pageNum: 1,
            pageSize: 15,
        },
    };
  },
  components: { },
  watch: {},
  mounted() {
    this.getBankCardChoiceList();
  },
  methods: {
    getBankCardChoiceList() {
        sid.marketList(this.queryParams).then((res) => {
            let arr=[]
            res.rows.forEach((item)=>{
                if(item.fileList !=undefined){
                    arr.push(item)
                }
            })
            this.bannerLists = arr;
            this.total = res.total;
        });
    },
      returnTap(){
          this.$router.go(-1)
      },
      navTaps(item){
          this.$router.push({
              path: '/makerspaceDetail',
          })
          localStorage.setItem("marketSpace",JSON.stringify(item))
      },
  }
};
</script>
<style lang="scss" scoped>
.pagination-container{
    justify-content: center;
    align-items: center;
    display: flex;
    background: #f9f9f9;
}
  .tops{
    margin-top: 60px;
  }
  .market-info{
    display: flex;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .name{
    color: #333;
    font-size: 16px;
    margin-left: 8px;
  }
  .market-icon{
    img{
      width: 18px;
      height: 18px;
    }
  }
  .marketImg{
    position: absolute;
    right: 20px;
    top: 20px;
    img{
      width: 150px;
      height: 150px;
    }
  }
  .marketName{
    color: #333;
    font-size: 28px;
    font-weight: 600;
  }
  .return{
    cursor: pointer;
    width: 98px;
    height: 48px;
    background: #B8B8B8;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    color: #fff;
    font-size: 16px;
    margin-left: 60px;
    text-align: center;
    line-height: 48px;
    margin-top: 27px;
  }
  .content-width{
    padding: 20px 100px 20px 30px;
    display: flex;
    flex-wrap: wrap;
  }
  .marketList{
    position: relative;
    width: 500px;
    //height: 205px;
    background: #FFFFFF;
    box-shadow: inset 0px 0px 40px 0px #DBDBDB, 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 10px 10px 10px 10px;
    padding: 20px 25px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
</style>
